import React from 'react';
import Hotspot from './Hotspot';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '../store/store';

export const renderHotspot = (hotSpotDiv, args) => {
  ReactDOM.render(
    <Provider store={store}>
      <Hotspot title={args.title} modal={args.modal} />
    </Provider>,
    hotSpotDiv
  );
};
