import React, { Component } from 'react';
import classNames from "classnames";

class DiscoverTabs extends Component {
    state = {
        open: false,
        selected: 'Build Quality'
    };

    componentWillReceiveProps(newProps) {
        this.setState({
            selected: this.refs.tHeader.children[newProps.active].textContent
        });
    }

    dropDownTabs(e) {
        const { whichTab } = this.props;
        let tab = parseInt(e.target.dataset.tab);

        if (window.innerWidth > 1024) {
            if (window.isNaN(tab)) return false;
            whichTab(tab);
        } else if (window.innerWidth < 1023 && !e.target.closest('.tab-header.tab-header--active')) {
            this.setState({
                open: true,
            });
        } else if (e.target.matches('.tab-header__selected')) {
            this.setState({
                open: false,
            });
        } else {
            if (window.isNaN(tab)) return false;
            whichTab(tab);
            this.setState({
                open: false,
                selected: e.target.textContent
            });
        }
    }

    render () {
        const { active } = this.props;
        return (
            <div className={'tab-header' + classNames(this.state.open && ' tab-header--active')}
                 onClick={(e) => this.dropDownTabs(e)}
                 ref={'tHeader'}
            >
                <h3 className="tab-header__selected">
                    {this.state.selected}
                </h3>
                <h3
                    className={classNames(active === 1 && 'selected')}
                    data-tab="1"
                >
                    Build Quality
                </h3>
                <h3
                    className={classNames(active === 2 && 'selected')}
                    data-tab="2"
                >
                    Lorem Ipsum
                </h3>
                <h3
                    className={classNames(active === 3 && 'selected')}
                    data-tab="3"
                >
                    Lorem Ipsum
                </h3>
                <h3
                    className={classNames(active === 4 && 'selected')}
                    data-tab="4"
                >
                    Lorem Ipsum
                </h3>
            </div>
        );
    }
}

export default DiscoverTabs;
