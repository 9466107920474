import React, { Component } from 'react';
import '../js/imagerotator.js';
import { connect } from 'react-redux';
import { showModal } from '../store/actions';
import classNames from 'classnames';
import ZoomButtons from './ZoomButtons';
import { getLocalFilePath } from '../js/getLocalFilePath'

let rotator = null;
let rotatorApi = null;
let rotatorZoom = 0;
class Exterior extends Component {
  componentDidMount() {
    if (rotator) {
      return rotator.reload();
    }
    rotator = window.WR360.ImageRotator.Create('wr360PlayerId');
    rotator.licenseFileURL = getLocalFilePath('/license.lic');
    rotator.settings.configFileURL = getLocalFilePath('/jlr360.xml');
    rotator.settings.graphicsPath = getLocalFilePath('/images');
    rotator.settings.googleEventTracking = false;
    rotator.settings.responsiveBaseWidth = window.innerWidth;
    rotator.settings.responsiveMinHeight = window.innerHeight;
    rotator.settings.hideHotspotsOnZoom = true;

    window.addEventListener('resize orientationchange', () =>{
      rotator.settings.responsiveBaseWidth = window.innerWidth;
      rotator.settings.responsiveMinHeight = window.innerHeight;
    })

    rotator.settings.apiReadyCallback = api => {
      rotatorApi = api;
      api.hotspots.onActivate(hotspot => {
        const isLeft = hotspot.coordX < (window.innerWidth * 0.6)
        if (!isLeft) {
          hotspot.coordX = hotspot.coordX + (window.innerWidth * 0.4);
        }
        //when a hotspot is clicked, show the modal.
        let element = window.$(`#${hotspot.hotspotHtmlId}`)
        if (element && !element.hasClass('event_added')) {
          element.on('click touchend', () => {
            this.props.showModal(hotspot.hotspotConfig.hotspotInfo.txt);
            rotatorZoom = 0.6;
            rotatorApi.images.zoom(rotatorZoom, {x: hotspot.coordX, y: hotspot.coordY});
          })
          element.addClass('event_added')
        }
      });
    };

    rotator.runImageRotator();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.modal && this.props.modal === null) {
      this.resetZoom();
    }
  }

  zoomOut = () => {
    if (rotatorZoom === 0) return null;
    rotatorZoom -= 0.1;
    rotatorApi.images.zoom(rotatorZoom);
  };

  zoomIn = () => {
    if (rotatorZoom === 1) return null;
    rotatorZoom += 0.1;
    rotatorApi.images.zoom(rotatorZoom);
  };

  resetZoom = () => {
    if (rotatorZoom === 0) return null;
    rotatorZoom = 0;
    rotatorApi.images.zoom(rotatorZoom);
  };

  render() {
    const { visible } = this.props;
    return (
    <div style={{height: '100vh', width: '100vw'}}>
      <div
        id="wr360PlayerId"
        className={classNames('wr360_player', !visible && 'hidden')}
      >
        <ZoomButtons
          onZoomIn={() => this.zoomIn()}
          onZoomOut={() => this.zoomOut()}
          onReset={() => this.resetZoom()}
        />
      </div>
    </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showModal: type => dispatch(showModal(type))
  };
};

const mapStateToProps = state => {
  return {
    modal: state.modal
  };
};

Exterior.defaultProps = {
  visible: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exterior);
