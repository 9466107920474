import React from 'react'
import ImageWithLightbox from '../ImageWithLightbox'
import Video from '../Video'

export default [
  {
    'name': 'infotainment',
    'data': [<Video key={'2'} src={'/videos/interior/Interior5.mp4'}/>,
      <div key={'1'}>
        <h1>NEW DUAL SCREEN INFOTAINMENT SYSTEM</h1>
        <p>Jaguar’s Touch Pro Duo infotainment system, shared with the all-electric I-PACE, is also available for the
          first time on XE. Delivering intuitive control through a pair of seamlessly integrated high-resolution
          touchscreens, the lower screen can be used to adjust settings such as for climate control, without disrupting
          the upper screen. A new human-machine interface (HMI) is designed to minimise driver distraction by separating
          information and interactive controls logically, with a pair of tactile rotary controllers providing an
          essential physical connection between car and driver. Each rotary allows precise control over seat heating and
          cooling, cabin temperature and fan speed. The ergonomic design removes the need to look at the rotaries during
          operation and saves delving into menus or interacting with multiple hard buttons for key functions.</p>
      </div>]
  }, {
    'name': 'steering',
    'data': [
      <ImageWithLightbox key={'2'}
                         src={'/images/modals/interior/4.jpg'}/>,
      <div key={'1'}>
        <h1>NEW STEERING WHEEL</h1>
        <p>A new steering wheel, shared with the all-electric I-PACE, features hidden-until-lit graphics and tactile
          switches for intuitive control over key functions. Wrapped in soft grain leather, the wheel is accompanied by
          Satin Chrome gear shift paddles on R-Dynamic models.</p>
      </div>]
  }, {
    'name': 'frets',
    'data': [
      <ImageWithLightbox key={'2'}
                         src={'/images/modals/interior/13.jpg'}/>,
      <div key={'1'}>
        <h1>NEW ETCHED METAL SPEAKER FRETS</h1>
        <p>Metal speaker frets showcase an etched Jaguar monogram pattern, inspired by the heritage Jaguar logo which
          adorned the famous D-type.</p>
      </div>]
  }, {
    'name': 'headsUp',
    'data': [<Video key={'2'} src={'/videos/interior/Interior8.mp4'}/>,
      <div key={'1'}>
        <h1>NEXT GENERATION HEAD-UP DISPLAY</h1>
        <p>Jaguar’s latest generation Head-up Display also helps drivers to keep their eyes on the road. The full-colour
          display projects key information such as navigation directions, current speed data and the incoming caller
          contact name directly into the driver’s eye line.</p>
      </div>]
  }, {
    'name': 'mirror',
    'data': [<Video key={'2'} src={'/videos/interior/Interior4.mp4'}/>,
      <div key={'1'}>
        <h1>CLEARSIGHT INTERIOR REAR VIEW MIRROR</h1>
        <p>The first-in-segment ClearSight interior rear view mirror uses a rear-facing camera to feed images to a
          high-resolution screen within the rear-view mirror. A small toggle switch on the mirror allows the driver to
          switch seamlessly between the standard mirror and the camera feed, with the latter providing a wider 50-degree
          field of vision, enhancing safety on wide multi-lane carriageways. Designed to integrate seamlessly within the
          roof-mounted pod, the high-definition camera works in all conditions with a protective lip and clever
          hydrophobic lens coating to repel water and ensure the camera lens remains clean at all times.</p>
      </div>]
  }, {
    'name': 'control',
    'data': [
      <Video key={'2'} src={'/videos/interior/Interior9.mp4'}/>,
      <div key={'1'}>
        <h1>F-TYPE-DERIVED JAGUARDRIVE CONTROL SWITCH</h1>
        <p>Taking inspiration from the F-TYPE sportscar, new XE features the JaguarDrive Control ‘toggle’ switch, for a
          easy access to multiple driving modes including Dynamic Mode for a more visceral driving experience or Rain
          Ice and Snow mode for when conditions become more challenging.</p>
      </div>]
  }, {
    'name': 'wireless',
    'data': [<Video key={'2'} src={'/videos/interior/Interior10.mp4'}/>,
      <div key={'1'}>
        <h1>WIRELESS DEVICE CHARGING</h1>
        <p>New XE features wireless device charging for the first time, allowing occupants to place a compatible
          smartphone in the centre console for it to be charged without the need for cables.</p>
        <p>Connectivity has been enhanced with the introduction of a Smartphone Pack, which features Apple CarPlay(R)
          and Android Auto(TM). The system mirrors the driver’s smartphone on the 10-inch HD touchscreen allowing
          customers to access compatible apps quickly, easily and safely while on the move. XE also functions as a 4G
          Wi-Fi Hotspot, enabling up to eight devices to connect to the internet with the vehicle antenna providing the
          best possible signal.</p>
      </div>]
  }, {
    'name': 'sportShift',
    'data': [<Video key={'2'} src={'/videos/interior/Interior11.mp4'}/>,
      <div key={'1'}>
        <h1>F-TYPE-DERIVED SPORT SHIFT SELECTOR</h1>
        <p>The beautifully crafted Jaguar SportShift selector clearly illustrates new XE's close links with the F-TYPE
          flagship sportscar. Standard on every XE, the SportShift selector is ergonomically designed to facilitate
          intuitive, rapid manual gear changes of the 8-speed automatic gearbox.</p>
      </div>]
  }, {
    'name': 'dials',
    'data': [
      <ImageWithLightbox key={'2'}
                         src={'/images/modals/interior/5.jpg'}/>,
      <div key={'1'}>
        <h1>MULTI-FUNCTIONAL ROTARY DIALS</h1>
        <p>A pair of tactile rotary controllers provide an essential physical connection between car and driver.
          Featuring Jaguar's iconic monogram pattern, each rotary allows precise control over seat heating and cooling,
          cabin temperature and fan speed. The ergonomic design removes the need to look at the rotaries during
          operation and saves delving into menus or interacting with multiple hard buttons for key functions.</p>
      </div>]
  }, {
    'name': 'veneer',
    'data': [
      <ImageWithLightbox key={'2'}
                         src={'/images/modals/interior/3.jpg'}/>,
      <div key={'1'}>
        <h1>INCREASED USE OF PREMIUM MATERIALS</h1>
        <p>New XE's luxurious interior places larger swathes of premium materials closer to the every occupant. Beautifully
          finished door veneers are larger and now also feature on the doors in the rear cabin. Door armrests are wider
          and softer, while cold touch chrome detailing brings another level of tactility to the door grab handles.
          Everywhere you look in new XE's cabin there are beautiful details to be enjoyed.</p>
      </div>]
  }, {
    'name': 'driverDisplay',
    'data': [
      <ImageWithLightbox key={'2'} src={'/images/modals/interior/7.jpg'}/>,
      <div key={'1'}>
        <h1>INTERACTIVE DRIVER DISPLAY</h1>
        <p>New Jaguar XE features a 12.3-inch Interactive Driver Display, which replaces the traditional dials in the
          instrument cluster. It works seamlessly alongside the dual-screen infotainment system and head-up display to
          show key driving information right where you need it. XE's Interactive Driver Display can even be configured
          to display full screen navigation for minimal distractions and absolute clarity on the way to your
          destination.</p>
      </div>]
  },
  {
    'name': 'door',
    'data': [
      <ImageWithLightbox key={'2'}
                         src={'/images/modals/interior/6.jpg'}/>,
      <div key={'1'}>
        <h1>REDESIGNED DOOR WITH INCREASED STORAGE</h1>
        <p>All-new door trims now feature wrapped, angled grab handles which provide a more tactile and user friendly
          experience. This improvement also drastically increases the amount of stowage available within the doors,
          which now easily accommodate large drinks bottles and other small items. Door armrests are wider and softer
          than before, providing superior comfort.</p>
      </div>]
  }, {
    'name': 'bumper',
    'data': [<Video key={'2'} src={'/videos/exterior/Exterior2.mp4'}/>,
      <div key={'1'}>
        <h1>NEW FRONT BUMPER
        </h1>
        <p>New XE's enhanced exterior design uses bold graphics and flowing forms for a sharper appearance and more
          assertive stance. Continuing on from the beautifully judged proportions of the original car, new XE’s sporting
          intent has been taken up a level, with contemporary design cues inspired by the F-TYPE sportscar. XE appears
          wider and lower than before, with larger front apertures and muscular forms eluding to the car’s performance
          and advanced aerodynamics. R-Dynamic models include additional exterior design elements including aircraft
          winglet-inspired sculpted surfaces.</p>
      </div>]
  }, {
    'name': 'grille',
    'data': [
      <ImageWithLightbox key={'2'} src={'/images/modals/exterior/1.jpg'}/>,
      <div key={'1'}>
        <h1>NEW FRONT GRILLE</h1>
        <p>The enhanced grille features a new lattice mesh design with a fuller, curved profile; a design cue shared
          with I-PACE which highlights the muscularity of the car's design. Wider and sharper lower apertures create a
          more technical and purposeful impression, with crisply defined profiles and muscular, flowing forms. At every
          stage, Jaguar’s designers and engineers worked hand in hand to ensure that aesthetic, material and
          manufacturing elements were integrated to deliver a precise, refined execution, with the utmost attention to
          detail. This includes the new Adaptive Cruise Control radar which is now placed discreetly behind an enlarged
          badge on the front grille.</p>
      </div>]
  }, {
    'name': 'lights',
    'data': [<Video key={'2'} src={'/videos/exterior/Exterior3.mp4'}/>,
      <div key={'1'}>
        <h1>ALL-LED HEADLIGHTS WITH ANIMATED INDICATORS</h1>
        <p>New all-LED headlights feature distinctive, calligraphy-inspired 'J' blade daytime running light signature
          with laser-etched Jaguar monogram pattern and animated directional indicators. Auto High Beam Assist uses a
          forward-facing camera to detect oncoming traffic and automatically switch between high and low beam. Optional
          Jaguar Matrix technology uses the XE’s on-board camera system to adapt the shape of the beam – shutting off
          individual LEDs completely as another car approaches – to avoid dazzling oncoming drivers.</p>
      </div>]
  }, {
    'name': 'rearLamps',
    'data': [<Video key={'2'} src={'/videos/exterior/Exterior5.mp4'}/>,
      <div key={'1'}>
        <h1>SLIMMER ALL-LED TAIL-LIGHTS</h1>
        <p>Slimmer, all-LED tail-lights also contribute to new XE's confident stance. Complete with animated directional
          indicators and an updated signature light graphic, the new tail light signatures are an evolution of those
          found on the F-TYPE sportscar and align closely with the all-electric I-PACE.</p>
      </div>]
  }, {
    'name': 'valance',
    'data': [
      <ImageWithLightbox key={'2'}
                         src={'/images/modals/exterior/2.jpg'}/>,
      <div key={'1'}>
        <h1>NEW REAR VALANCE</h1>
        <p>XE's new rear valance is wider and more defined, grounding the car and adding to its dramatic stance. On
          R-Dynamic models, the rear valance features dark mesh detailing, underlining the car's inherent
          sportiness.</p>
      </div>]
  }, {
    'name': 'rearBumper',
    'data': [
      <Video key={'2'}
             src={'/videos/exterior/Exterior7.mp4'}/>,
      <div key={'1'}>
        <h1>NEW REAR BUMPER</h1>
        <p>The rear of the car features a new bumper design for increased aerodynamic performance and a more muscular
          look. The new bumper houses XE's all-LED tail-lights and sportier rear valance.</p>
      </div>]
  }, {
    'name': 'seatComfort',
    'data': [
      <ImageWithLightbox key={'2'} src={'/images/modals/interior/9.jpg'}/>,
      <div key={'1'}>
        <h1>IMPROVED SEAT COMFORT</h1>
        <p>New seats deliver enhanced comfort and attention to detail, including twin needle stitching and new Jaguar
          seat labelling. HSE models feature soft Windsor leather and embossed headrests. Sports-style seats with
          contrast stitching feature on R-Dynamic models.</p>
      </div>]
  },
  {
    'name': 'body',
    'data': [
      <Video key={'2'} src={'/videos/exterior/Exterior9.mp4'}/>,
      <div key={'1'}>
        <h1>LIGHTWEIGHT ALUMINIUM INTENSIVE CONSTRUCTION</h1>
        <p>Jaguar XE’s lightweight aluminium intensive body structure continues to play a major role in the car’s agile
          handling, exceptional safety and efficiency. Aluminium makes up 75 per cent of the body and combines with
          proven rear and all-wheel drive systems, double wishbone front and integral link rear suspension, plus some of
          Jaguar’s most advanced engines, for dynamic handling and performance.</p>
      </div>]
  }
]
