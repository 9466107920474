import React, { Component } from 'react';
import classNames from "classnames";

class ModeSwitcher extends Component {
  state = { value: 'exterior' };

  updateValue = event => {
    const { selection } = event.target.dataset;
    const { onChange } = this.props;

    this.setState({ value: selection });
    onChange(selection);
  };

  render() {
    const { value } = this.state;
    return (
      <div className="mode-switcher">
          <ul className={'mode-switcher__list'}>
              <li className={classNames('mode-switcher__item', value === 'exterior' && 'mode-switcher__item--active')}
                onClick={this.updateValue}
                data-selection="exterior">Exterior</li>
              <li className={classNames('mode-switcher__item', value === 'interior' && 'mode-switcher__item--active')}
                  onClick={this.updateValue}
                  data-selection="interior">Interior</li>
          </ul>
          <div className={classNames('mode-switcher__line', value === 'interior' && 'mode-switcher__line--changed')}></div>
      </div>
    );
  }
}

export default ModeSwitcher;
