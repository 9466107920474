import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';

class ImageWithLightbox extends Component {
  state = { fullScreen: false };

  renderLightbox = () => {
    const { fullScreen } = this.state;
    if (fullScreen === false) return null;

    const { src } = this.props;
    return (
      <Lightbox
        mainSrc={src}
        onCloseRequest={() => this.setState({ fullScreen: false })}
      />
    );
  };


  render() {
    const { src } = this.props;
    const imgStyle = {
        backgroundImage: `url('${src}')`
    };

    return (
      <div>
        {this.renderLightbox()}
        <div className="three-sixty-image-container margin-top-lg">
          <div
            className="full-screen-button hide-on-mobile icon-resize-full-alt"
            onClick={() => this.setState({ fullScreen: true })}
          >
          </div>
            <div alt='jaguar land rover car'
                 className="margin-auto responsive fs-on-mobile three-sixty-image-container__img"
                 style={imgStyle}></div>
        </div>
      </div>
    );
  }
}

ImageWithLightbox.defaultProps = {
  src: 'https://auto.ndtvimg.com/car-images/big/jaguar/xj/jaguar-xj.jpg?v=10'
};

export default ImageWithLightbox;
