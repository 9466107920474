import React, { Component } from 'react';
import DiscoverTabs from '../DiscoverTabs';

class DiscoverModal extends Component {
  state = { activeTab: 1 };

  setActiveTab = tab => {
    this.setState({
      activeTab: tab
    });
  };

  renderFirstTab = () => {
    return (
      <div className="tab-content">
        <img
          alt='jaguar land rover car'
          className="margin-v-sm"
          src="https://crain-platform-autonews-europe-prod.s3.amazonaws.com/s3fs-public/ANE_181209866_AR_-1_OECXMOMHTCUQ.jpg"
        />
        <div className="tab-body-content">
          <h3> Subtitle</h3>
          <p>
            Body Content is cool, Body Content is cool, Body Content is cool,
            Body Content is cool
          </p>
          <div className="tab-arrows">
            <span className="inline --hidden">
              &lt;
            </span>
            <span onClick={() => this.setActiveTab(2)} className="inline">
              &gt;
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderSecondTab = () => {
    return (
      <div className="tab-content">
        <img
          alt='jaguar land rover car'
          className="margin-v-sm"
          src="https://crain-platform-autonews-europe-prod.s3.amazonaws.com/s3fs-public/ANE_181209866_AR_-1_OECXMOMHTCUQ.jpg"
        />
        <div className="tab-body-content">
          <h3> Subtitle 2</h3>
          <p>Body Content is cool, Body Content is cool, Body Content is cool, Body Content is cool</p>
          <div className="tab-arrows">
            <span onClick={() => this.setActiveTab(1)} className="inline">
              &lt;
            </span>
            <span onClick={() => this.setActiveTab(3)} className="inline">
              &gt;
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderThirdTab = () => {
    return (
      <div className="tab-content">
        <img
          alt='jaguar land rover car'
          className="margin-v-sm"
          src="https://crain-platform-autonews-europe-prod.s3.amazonaws.com/s3fs-public/ANE_181209866_AR_-1_OECXMOMHTCUQ.jpg"
        />
        <div className="tab-body-content">
          <h3> Subtitle 3</h3>
          <p>Body Content is cool, Body Content is cool, Body Content is cool, Body Content is cool</p>
          <div className="tab-arrows">
            <span onClick={() => this.setActiveTab(2)} className="inline">
              &lt;
            </span>
            <span onClick={() => this.setActiveTab(4)} className="inline">
              &gt;
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderFourthTab = () => {
    return (
      <div className="tab-content">
        <img
          alt='jaguar land rover car'
          className="margin-v-sm"
          src="https://crain-platform-autonews-europe-prod.s3.amazonaws.com/s3fs-public/ANE_181209866_AR_-1_OECXMOMHTCUQ.jpg"
        />
        <div className="tab-body-content">
          <h3> Subtitle 4</h3>
          <p>Body Content is cool, Body Content is cool, Body Content is cool, Body Content is cool</p>
          <div className="tab-arrows">
            <span onClick={() => this.setActiveTab(3)} className="inline">
              &lt;
            </span>
            <span className="inline --hidden">
              &gt;
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderContent() {
    let renderFn = () => {};
    switch (this.state.activeTab) {
      case 1:
        renderFn = this.renderFirstTab;
        break;
      case 2:
        renderFn = this.renderSecondTab;
        break;
      case 3:
        renderFn = this.renderThirdTab;
        break;
      case 4:
        renderFn = this.renderFourthTab;
        break;
      default:
        renderFn = this.renderFirstTab;
    }
    return <div>{renderFn()}</div>;
  }

  whichTab(n) {
      this.setActiveTab(n);
  }

  render() {
    return (
      <div className="discover">
        <h1 className="modal-title">Discover</h1>
        <DiscoverTabs
            whichTab={(n) => this.whichTab(n)}
            active={this.state.activeTab}
        />
          {this.renderContent()}
      </div>
    );
  }
}

export default DiscoverModal;
