export const reducers = (state = [], action) => {
  switch (action.type) {
    case 'show_modal':
      return { modal: action.payload };
    case 'hide_modal':
      return { modal: null };
    default:
      return state;
  }
};
