import React, { Component } from 'react';

class ZoomButtons extends Component {
  render () {
    const { onZoomIn, onZoomOut, onReset } = this.props;
    return (
      <div className={'buttons'}>
        <div className='zoom-button in' onClick={onZoomIn}>
          <i className='icon-plus' />
        </div>
        <div className='zoom-button out' onClick={onZoomOut}>
          <i className='icon-minus' />
        </div>
        {onReset && (
          <div className='zoom-button reset' onClick={onReset}>
            <i className='icon-arrows-cw' />
          </div>
        )}
      </div>
    );
  }
}

export default ZoomButtons;
