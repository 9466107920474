import React, { Component } from 'react';

class DiscoverButton extends Component {
  render () {
    return (
      <div className="mode-switcher mode-switcher__discover">
        <ul className={'mode-switcher__list'}>
          <li className={'mode-switcher__item mode-switcher__item--active'}
              onClick={()=> window.open("https://buildyour.jaguar.com/jag2/r/model/_/en_xi/xe_k20/", "_blank")}
              data-selection="build-yours">BUILD YOURS</li>
          <li className={'mode-switcher__item mode-switcher__item--active'}
              onClick={()=> window.open("https://www.jaguar.com/jaguar-range/xe/index.html", "_blank")}
              data-selection="discover-more">DISCOVER MORE</li>
        </ul>
      </div>
    );
  }
}

DiscoverButton.defaultProps = {
  onClick: () => {}
};

export default DiscoverButton;
