import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../store/actions'
import modals from './modals'
import DiscoverModal from './modals/DiscoverModal'

class Modal extends Component {


  constructor(props) {
    super(props)
    console.log(window.innerWidth < 1023)
    this.state = {
      pos: 0,
      prev: false,
      next: true,
      mobile: window.innerWidth < 1023
    }
    document.addEventListener('resize', () => {
        this.setState({
          mobile: window.innerWidth < 1023
        })
    })
  }

  componentDidUpdate (prevProps) {
    const { modal } = this.props
    if (modal === null || modal === 'discover') return false
    if (prevProps.modal === modal) return false

    const data = modals.find(m => m.name === modal).data

    this.setState({
      ln: data.length,
      mobile: window.innerWidth < 1023
    })
  }

  resetState = () => {
    this.setState({ pos: 0, prev: false, next: true })
  }

  render () {
    const { modal, closeModal } = this.props
    if (modal === null) return null

    return (
      <div className={ `three-sixty-modal fs-on-mobile modal-${ modal }` }>
        <div className='three-sixty-modal-content fs-on-mobile'>
          { this.renderArrows() }
          <div onClick={ () => {
            this.resetState()
            closeModal()
          } } title='Close' className='three-sixty-modal-close'>
            <img alt="Close button" src="/images/close.svg"/>
          </div>
          { this.renderContent() }
        </div>
      </div>
    )
  }

  renderContent () {
    const { modal } = this.props
    const { pos, mobile } = this.state
    if (modal === 'discover') {
      return <DiscoverModal/>
    }

    const data = modals.find(m => m.name === modal).data

    if (mobile) {
      const reverseData = [...data].reverse()
      return <div className={ `modal-inner-content panel-${ pos } reversed` }>{ reverseData }</div>
    }
    return <div className={ `modal-inner-content panel-${ pos }` }>{ data }</div>
  }

  renderArrows () {
    const { modal } = this.props

    if (modal !== 'discover') {
      return (
        <div className="modal-arrows">
                  <span className={ !this.state.prev ? '--hidden' : null }
                        onClick={ () => this.setPos(-1) }>
                     &lt;
                  </span>
          <span className={ !this.state.next ? '--hidden' : null } onClick={ () => this.setPos(1) }>
                     &gt;
                   </span>
        </div>
      )
    } else {
      return null
    }
  }

  setPos (n) {
    const newPos = this.state.pos + n
    if (newPos < 0 || newPos >= this.state.ln) return false

    this.setState({ prev: newPos !== 0 })
    this.setState({ next: newPos !== this.state.ln - 1 })

    this.setState({
      pos: newPos
    })
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(hideModal())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal)
