import { renderHotspot } from '../components/RenderHotspot';

export const InternalHotspots = onClick => {
  return [
    {
      pitch: -18.6,
      yaw: 7.9,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'NEW DUAL SCREEN INFOTAINMENT SYSTEM',
        modal: 'infotainment'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -18.6,
        yaw: 7.9,
      }
    },
    {
      pitch: -9,
      yaw: -38,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'NEW STEERING WHEEL',
        modal: 'steering'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -10,
        yaw: -23
      }
    },
    {
      pitch: -11,
      yaw: 42,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'NEW ETCHED METAL SPEAKER FRETS',
        modal: 'frets'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -11,
        yaw: 45,
      }
    },
    {
      pitch: 11,
      yaw: 0,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'CLEARSIGHT INTERIOR REAR VIEW MIRROR',
        modal: 'mirror'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: 9,
        yaw: 0
      }
    },
    {
      pitch: -39,
      yaw: -7,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'F-TYPE-DERIVED JAGUARDRIVE CONTROL SWITCH',
        modal: 'control'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -35,
        yaw: 0
      }
    },
    {
      pitch: -2,
      yaw: -18,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'NEXT GENERATION HEAD-UP DISPLAY',
        modal: 'headsUp'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -2,
        yaw: -18,
      }
    },
    {
      pitch: -32.4,
      yaw: -5.5,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'WIRELESS DEVICE CHARGING',
        modal: 'wireless'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -32.4,
        yaw: -5.5,
      }
    },
    {
      pitch: -38,
      yaw: 0,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'F-TYPE-DERIVED SPORTSHIFT SELECTOR',
        modal: 'sportShift'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -35,
        yaw: 0,
      }
    },
    {
      pitch: -27,
      yaw: 6.5,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'MULTI-FUNCTIONAL ROTARY DIALS',
        modal: 'dials'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -27,
        yaw: 6.6,
      }
    },
    {
      pitch: -12.6,
      yaw: 56.1,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'INCREASED USE OF PREMIUM MATERIALS',
        modal: 'veneer'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -12.6,
        yaw: 56.1,
      }
    },
    {
      pitch: -12,
      yaw: -22,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'INTERACTIVE DRIVER DISPLAY',
        modal: 'driverDisplay'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -12,
        yaw: -18,
      }
    },
    {
      pitch: -24.2,
      yaw: 62.7,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'REDESIGNED DOORS WITH INCREASED STORAGE',
        modal: 'door'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -24.2,
        yaw: 62.7,
      }
    },
    {
      pitch: -29,
      yaw: 119,
      cssClass: 'custom-hotspot',
      createTooltipFunc: renderHotspot,
      createTooltipArgs: {
        title: 'INCREASED SEAT COMFORT',
        modal: 'seatComfort'
      },
      clickHandlerFunc: onClick,
      clickHandlerArgs: {
        pitch: -29,
        yaw: 119,
      }
    },
  ];
};
