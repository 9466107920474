import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal } from '../store/actions';
import { getLocalFilePath } from '../js/getLocalFilePath'

class Hotspot extends Component {
  render () {
    const { title, modal, showModal } = this.props;
    return (
      <div onClick={() => showModal(modal)} className='hotspot'>
          <img alt={`${title} - hotspot icon`} src={getLocalFilePath('/images/hotspot-icon.svg')} className='hotspot-icon' />
          <p className='hotspot__text'> { title } </p>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showModal: type => dispatch(showModal(type))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Hotspot);
