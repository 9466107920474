import React, { Component } from 'react';
import { InternalHotspots } from '../config/InternalHotspots';
import ZoomButtons from './ZoomButtons';
import { connect } from 'react-redux';
import pannellum from 'pannellum'
import { getLocalFilePath } from '../js/getLocalFilePath'

class Interior extends Component {
  viewer = null;
  defaultZoom = 0;
  componentDidMount() {
    this.viewer = window.pannellum.viewer('interior', {
      "type": "multires",
      "multiRes": {
        "basePath": getLocalFilePath('/images/interior/multires/'),
        "path": "/%l/%s%y_%x",
        "fallbackPath": "/fallback/%s",
        "extension": "jpg",
        "tileResolution": 512,
        "maxLevel": 4,
        "cubeResolution": 3176
      },
      autoLoad: true,
      hotSpots: InternalHotspots(this.zoomToPoint),
      showFullscreenCtrl: false,
      showControls: false,
      preview: getLocalFilePath('/images/preview.png'),
    });

    this.defaultZoom = this.viewer.getHfov();
    window.addEventListener('resize', () =>{
      this.viewer.getRenderer().resize()
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.modal && this.props.modal === null) {
      this.resetZoom();
    }
  }

  resetZoom = () => {
    this.viewer.setHfov(this.defaultZoom);
  };

  zoomToPoint = (event, args) => {
    this.viewer.lookAt(args.pitch, args.yaw, 25);
  };

  zoomIn = () => {
    this.viewer.setHfov(this.viewer.getHfov() - 25);
  };

  zoomOut = () => {
    this.viewer.setHfov(this.viewer.getHfov() + 25);
  };

  render() {
    return (
      <div className="interior" id="interior">
        <ZoomButtons
          onZoomIn={() => this.zoomIn()}
          onZoomOut={() => this.zoomOut()}
          onReset={() => this.resetZoom()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal
  };
};

export default connect(mapStateToProps)(Interior);
