import React, { Component } from 'react';
import classNames from 'classnames';

class Video extends Component {
  state = {
      fullScreen: false,
      playing: false
    };

  render() {
    const { src } = this.props;
    return (
      <div className={classNames('three-sixty-image-container margin-top-lg', this.state.playing && 'video-playing')}>
        <div className="play-marker">
            <img alt="play icon" src="/images/play.svg"
                 onClick={() => {
                   const vid = document.getElementById('video');
                   vid.play();
                   vid.controls = true;
                 }}/>
        </div>
        <video
          onPlay={() => this.setState({ playing: true })}
          onPause={() => this.setState({ playing: false })}
          id="video"
          className="margin-auto responsive"
          src={src}
        >
          <source src={src} type="video/mp4" />
        </video>
      </div>
    );
  }
}

Video.defaultProps = {
  src: 'https://auto.ndtvimg.com/car-images/big/jaguar/xj/jaguar-xj.jpg?v=10'
};

export default Video;
