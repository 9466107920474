import React, { Component, Fragment } from 'react';
import './App.scss';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import './fonts/css/icons.css';
import './fonts/css/jlr.css';
import Exterior from './components/Exterior';
import Interior from './components/Interior';
import ModeSwitcher from './components/ModeSwitcher';
import DiscoverButton from './components/DiscoverButton';
import Modal from './components/Modal';
import { connect } from 'react-redux';
import { hideModal, showModal } from './store/actions';

class App extends Component {
  state = {
    mode: 'exterior',
    overlay: true
  };

  renderOverlay() {
    const { overlay } = this.state;
    if (!overlay) return null;

    return (
      <div
        className="three-sixty-overlay"
        onClick={() => this.setState({ overlay: false })}
        onTouchStart={() => this.setState({ overlay: false })}
      >
        <img alt='click and drag to explore icon' src="/images/overlay-icon.svg" />
        <p> Click and Drag to Explore the Vehicle </p>
      </div>
    );
  }

  renderMode() {
    const { mode } = this.state;
    return (
      <Fragment>
        <Exterior visible={mode === 'exterior'} />
        {mode === 'interior' && <Interior showModal={this.renderModal()} />}
      </Fragment>
    );
  }

  renderModal() {
    return (
      <Modal
        onClose={() => {
          this.setState({ modal: false });
        }}
      />
    );
  }

  renderWarningModal() {
    return (
      <div id='three-sixty-warning-message'>
        <p>This app only works in landscape, please rotate your device.</p>
      </div>
    )
  }

  render() {
    const { showModal, hideModal } = this.props;

    return (
      <div className='three-sixty-spinner'>
        {this.renderWarningModal()}
        <div id='three-sixty-wrapper'>
            {this.renderOverlay()}
            <ModeSwitcher
              onChange={mode => {
                hideModal();
                this.setState({ mode });
              }}
            />
            <DiscoverButton onClick={() => showModal('discover')} />
            {this.renderMode()}
            {this.renderModal()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showModal: type => dispatch(showModal(type)),
    hideModal: () => dispatch(hideModal())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(App);
